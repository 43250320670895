import { Flex } from '@chakra-ui/react';

import type { FlexProps } from '@chakra-ui/react';

interface CardBodyProps extends Omit<FlexProps, 'onClick'> {
  onClick?: () => boolean;
}

export const CardBody = (props: CardBodyProps): JSX.Element => {
  const { onClick, ...rest } = props;
  return (
    <Flex
      bg="white"
      w="100%"
      rounded="md"
      color="dark.500"
      onClick={onClick}
      overflow="hidden"
      borderWidth="1px"
      direction="column"
      {...rest}
    />
  );
};
