import { Box } from '@chakra-ui/react';

import type { BoxProps } from '@chakra-ui/react';

export const CodeBlock = (props: BoxProps): JSX.Element => {
  return (
    <Box
      p={3}
      mt={5}
      bg="blackAlpha.100"
      as="pre"
      border="1px"
      rounded="md"
      color="black"
      fontSize="sm"
      fontFamily="mono"
      borderColor="inherit"
      whiteSpace="pre-wrap"
      {...props}
    />
  );
};
