import { Image, Skeleton } from '@chakra-ui/react';
import { useConfig } from '~/context';
import { useColorValue } from '~/hooks';

import type { ImageProps } from '@chakra-ui/react';

export const Logo = (props: ImageProps): JSX.Element => {
  const { web } = useConfig();
  const { lightFormat, width } = web.logo;

  return (
    <Image
      src={`/images/light${lightFormat}`}
      alt={web.text.title}
      maxW={{ base: '100%', md: width }}
      width="auto"
      css={{
        userDrag: 'none',
        userSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserDrag: 'none',
        WebkitUserSelect: 'none',
      }}
      fallback={
        <Skeleton
          isLoaded={false}
          borderRadius="md"
          endColor="light.500"
          startColor="whiteSolid.100"
          width={{ base: 64, lg: 80 }}
          height={{ base: 12, lg: 16 }}
        />
      }
      {...props}
    />
  );
};
