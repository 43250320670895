import { Image, Skeleton } from '@chakra-ui/react';
import { useConfig } from '~/context';

import type { ImageProps } from '@chakra-ui/react';

export const Logo = (props: ImageProps): JSX.Element => {
  const { web } = useConfig();
  const { footerFormat } = web.logo;

  return (
    <Image
      src={`/images/footer${footerFormat}`}
      alt={web.text.title}
      width="100%"
      css={{
        objectFit: 'contain',
        objectPosition: 'top',
        userDrag: 'none',
        userSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserDrag: 'none',
        WebkitUserSelect: 'none',
      }}
      fallback={
        <Skeleton
          isLoaded={false}
          borderRadius="md"
          endColor="light.500"
          startColor="whiteSolid.100"
          width="100%"
        />
      }
      {...props}
    />
  );
};
