import {
  Popover,
  PopoverBody,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
} from '@chakra-ui/react';

import type { PromptProps } from './types';

export const DesktopPrompt = (props: PromptProps): JSX.Element => {
  const { trigger, children, ...disclosure } = props;

  return (
    <Popover closeOnBlur={false} {...disclosure}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent bg="white">
        <PopoverArrow bg="white" />
        <PopoverCloseButton />
        <PopoverBody p={6}>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
