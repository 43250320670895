import { Button, Link } from '@chakra-ui/react';

import type { ButtonProps, LinkProps } from '@chakra-ui/react';

type FooterLinkProps = ButtonProps & LinkProps & { title: string };

export const FooterLink = (props: FooterLinkProps): JSX.Element => {
  const { title } = props;
  return (
    <Button
      as={Link}
      isExternal
      py={1}
      fontWeight="normal"
      variant="link"
      colorScheme="transparent"
      aria-label={title}
      {...props}
    >
      {title}
    </Button>
  );
};
