import { Modal, ModalBody, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';

import type { PromptProps } from './types';

export const MobilePrompt = (props: PromptProps): JSX.Element => {
  const { children, trigger, ...disclosure } = props;
  return (
    <>
      {trigger}
      <Modal
        size="xs"
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        {...disclosure}
      >
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalCloseButton />
          <ModalBody px={4} py={10}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
